import axios from 'axios'
import { camelCase, mapKeys, pick } from 'lodash'

export type Asset = {
  name: string,
  description: string,
  imageUrl: string,
  permalink: string,
  collection: string,
  id: number
}

export const fetchAssets = async (address: string): Promise<Asset[]> => {
  // Set request params
  const params = {
    order_direction: 'desc',
    // NOTE! Offset deactivated due to OpenSea API bug
    // offset: '0',
    limit: '40',
    owner: address
  }

  // Debug: Trigger Opensea error via query param
  const triggerError = new URLSearchParams(location.search).get('triggerError') === 'true'
  if (triggerError) throw new Error('Fake Opensea Error')

  // Fetch assets
  const url = 'https://api.opensea.io/api/v1/assets'
  const { data: { assets } } = await axios.get(url, { params })

  // Pick relevant fields)
  return assets
    .map(asset => pick(asset, [
      'name',
      'description',
      'image_url',
      'permalink',
      'collection',
      'id'
    ]))
    // snake_case -> camelCase
    .map((asset: Asset) => mapKeys(asset, (_value, key) => camelCase(key)))
    // Rewrite collection field to only its name
    .map(asset => ({ ...asset, collection: asset.collection.name }))
}
