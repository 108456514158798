import React from 'react' // eslint-disable-line no-use-before-define
import PropTypes from 'prop-types'

// @ts-expect-error Conversion from .sass to .css done during build
import * as styles from './LoadingScreen.module.sass'

type ButtonProps = {
  text: string,
  target: string
}

const Button = ({ text, target }: ButtonProps) =>
  <div className={styles.button} onClick={() => window.open(target, '_self') }>
    <div className={styles.buttonText}>{text}</div>
  </div>

const LoadingScreen = ({ error }) => {
  return (
    <div className={styles.loading}>
      { error
        ? <div className={styles.errorContainer}>
            <div className={styles.errorMessage}>{error.message}</div>
            <Button text={error.buttonText} target={error.redirectTarget} />
          </div>
        : <img src="/images/powered_by_hiberworld.svg" />
      }
    </div>
  )
}

LoadingScreen.propTypes = {
  error: PropTypes.object
}

export default LoadingScreen
