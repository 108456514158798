import { v4 as uuid } from 'uuid'

export default class _u {
  static q (selector) {
    return document.querySelector(selector)
  }

  static dd = (className) => {
    return _u.q('select.' + className)
  };

  static defVal = (input, defaultValue) => {
    if (typeof input === 'undefined') return defaultValue
    else if (input === 0) return 0
    else if (input === false) return false
    else if (!input) return defaultValue
    else return input
  };

  static addAfter = (afterThis, htmlCode) => {
    const arr = _u.getElementAsArray(afterThis)
    if (arr) {
      for (let index = 0; index < arr.length; index++) {
        const element = arr[index]
        element.insertAdjacentHTML('afterend', htmlCode)
      }
    }
  };

  static addWrapper = (addTo, htmlCode, tag = 'div') => {
    const div = document.createElement(tag)
    div.innerHTML = htmlCode
    addTo.appendChild(div)
    return div
  };

  static each (target, action) {
    let i
    let index = 0
    let key
    if (!isNaN(target)) {
      for (i = 0; i < target; i += 1) {
        action(i)
      }
    } else if (target instanceof Array) {
      const len = target.length
      for (i = 0; i < len; i += 1) {
        action(target[i], i)
      }
    } else {
      for (key in target) {
        if (target[key]) {
          action(target[key], key, index)
          index++
        }
      }
    }
  }

  static download = (content, filename, mimeType) => {
    mimeType = mimeType || 'text/plain'
    const a = document.createElement('a')
    const blob = new Blob([content], {
      type: mimeType
    })
    const url = URL.createObjectURL(blob)
    a.setAttribute('href', url)
    a.setAttribute('download', filename)
    a.click()
  };

  static request = (param) => {
    return _u.getQuery(param)
  }

  static getQuery = (param) => {
    return new URLSearchParams(window.location.search).get(param)
  };

  static setQuery = (param, value) => {
    const qp = new URLSearchParams(window.location.search)
    qp.set(param, value)
    history.replaceState(null, null, '?' + qp.toString())
  };

  static bb (target) {
    return target.getBoundingClientRect()
  }

  static rotateAroundAPoint (cx, cy, x, y, deg) {
    const radians = (Math.PI / 180) * deg
    const cos = Math.cos(radians)
    const sin = Math.sin(radians)
    const nx = (cos * (x - cx)) + (sin * (y - cy)) + cx
    const ny = (cos * (y - cy)) - (sin * (x - cx)) + cy
    return [nx, ny]
  }

  static width (target) {
    return target.offsetWidth
  }

  static height (target) {
    return target.offsetHeight
  }

  static size (obj) {
    return Object.keys(obj).length
  }

  static first (obj) {
    return obj[Object.keys(obj)[0]]
  }

  static firstKey (obj) {
    return Object.keys(obj)[0]
  }

  static clone (obj) {
    let rv
    try {
      rv = JSON.parse(JSON.stringify(obj))
    } catch (err) {
      rv = obj
    }
    return rv
  }

  static objectToArray (obj) {
    const arr = []
    _u.each(obj, (o, key) => {
      o.key = key
      arr.push(o)
    })
    return arr
  }

  static arrayToObject (arr) {
    const obj = {}
    _u.each(arr, key => {
      obj[key] = key
    })
    return obj
  }

  static sortByKey (array, key, order) {
    return array.sort((a, b) => {
      const x = typeof a[key].getMonth === 'function' ? a[key].getTime() : a[key]
      const y = typeof b[key].getMonth === 'function' ? b[key].getTime() : b[key]
      if (order === 'desc') {
        return x > y ? -1 : x < y ? 1 : 0
      }
      return x < y ? -1 : x > y ? 1 : 0
    })
  }

  static filterArray = (arrayToFilter, key, val) => {
    return arrayToFilter.filter(item => (item[key] === val))
  };

  static shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]
    }
    return array
  }

  static rand (from, to) {
    return Math.floor(Math.random() * (to - from + 1) + from)
  }

  static randFloat (from, to) {
    return (Math.random() * (to - from) + from).toFixed(4)
  }

  static randFromArray (array, length) {
    // If length is defined and array of that length is returned.
    if (length) {
      if (length >= array.length) {
        const rv = []
        for (let index = 0; index < length; index++) {
          rv.push(_u.randFromArray(array))
        }
        return rv
      } else {
        const clone = _u.clone(array)
        while (clone.length > length) {
          clone.splice(Math.floor(Math.random() * clone.length), 1)
        }
        return clone
      }
    } else {
      return array[Math.floor(Math.random() * array.length)]
    }
  }

  static round (number, decimals) {
    const d = 10 * decimals
    return (((number * d + 0.5) << 1) >> 1) / d
  }

  // Events
  static events = {};

  // Fire custom event
  static fire = function (eventName, value, value2) {
    const group = _u.events[eventName]
    if (!group) return (false)

    let l = group.length
    while (l-- > 0) {
      group[l] && group[l](value, value2)
    }
  };

  static getElementAsArray = (target) => {
    if (target && typeof target === 'string') {
      return Array.from(document.querySelectorAll(target))
    } else if (target) { // Set event based on element object.
      return Array.from(target)
    }
  };

  static on = function (eventName, callback, target) {
    if (target && (Array.isArray(target) || NodeList.prototype.isPrototypeOf(target))) {
      target.forEach(el => {
        el.addEventListener(eventName, callback)
      })
    } else if (target) {
      const res = _u.getElementAsArray(target)
      _u.on(eventName, callback, res)
    } else {
      if (!_u.events[eventName]) { _u.events[eventName] = [callback] } else { _u.events[eventName].unshift(callback) }
    }
  };

  static off = function (eventName, callback, target) {
    if (target && (Array.isArray(target) || NodeList.prototype.isPrototypeOf(target))) {
      target.forEach(el => {
        el.removeEventListener(eventName, callback)
      })
    } else if (target) {
      const res = _u.getElementAsArray(target)
      _u.off(eventName, callback, res)
    } else {
      const group = _u.events[eventName]
      if (!group) return (false)
      let l = group.length
      while (l-- > 0) {
        if (group[l] && group[l] === callback) {
          group[l] = null
          break
        }
      }
    }
  };

  static now () {
    return performance.now() || Date.now()
  }

  static isMobile () {
    return /Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent)
  }

  static isMac () {
    return navigator.platform.toUpperCase().indexOf('MAC') >= 0
  }

  static loadJSON (path, success, error) {
    _u.load(path, success, error, true)
  }

  static load (path, success, error, asJson) {
    const xhr = new XMLHttpRequest()
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          if (success) {
            if (asJson) success(JSON.parse(xhr.responseText))
            else success(xhr.responseText)
          }
        } else {
          if (error) { error(xhr) }
        }
      }
    }
    xhr.open('GET', path, true)
    xhr.send()
  }

  static index = 0
  static getIndex = () => {
    _u.index++
    return _u.index
  };

  static getId = () => uuid()

  static pathToId (val) {
    val = val.toLowerCase()
    val = val.replace(/[^(!_)a-zA-Z 0-9]+/g, '')
    val = val.replace(/^\s+|\s+$/gm, '')
    val = val.split(' ').join('_')
    return val
  }

  static removeExtension (filename) {
    return filename.split('.').slice(0, -1).join('.')
  }

  static fancify (str) {
    str = str.split('_').join(' ')
    str = str.split('$').join('.')
    str = str.substr(0, 1).toUpperCase() + str.substr(1)
    return str
  }

  static stringCast (s) {
    if (typeof s !== 'string') {
      return s
    }

    const check = function (s) {
      if (s.toLowerCase() === 'true') {
        return true
      }
      if (s.toLowerCase() === 'false') {
        return false
      }
      if (!isNaN(s.replace(',', '.'))) {
        s = s.replace(',', '.')
        if (s.indexOf('.')) {
          return parseFloat(s)
        }
        return parseInt(s)
      }
      return s
    }

    if (s === '') {
      return ''
    }

    if (s.indexOf('{') === -1) {
      return check(s)
    }

    try {
      const o = JSON.parse(s)
      if (o && typeof o === 'object' && o !== null) {
        return o
      }
    } catch (e) {
      check(s)
    }
  }

  static prettyJSON (jsObj) {
    return JSON.stringify(jsObj, null, '\t')
  }

  static objToJSON (jsObj) {
    return JSON.stringify(jsObj)
  }

  static scriptLoader = (scripts, callback) => {
    let count = scripts.length

    function urlCallback (url) {
      return function () {
        // console.log(url + ' was loaded (' + --count + ' more scripts remaining).');
        --count
        if (count < 1) {
          callback()
        }
      }
    }

    function loadScript (url) {
      const s = document.createElement('script')
      s.setAttribute('src', url)
      s.onload = urlCallback(url)
      document.head.appendChild(s)
    }
    for (const script of scripts) {
      loadScript(script)
    }
  }
}
