import React from 'react' // eslint-disable-line no-use-before-define
import PropTypes from 'prop-types'

// @ts-expect-error Conversion from .sass to .css done during build
import * as styles from './AssetInfoDesktop.module.sass'

const AssetInfo = ({ imageUrl, name, collection }) => {
  return (
    <div className={styles.assetInfo}>
      {/* Filler */}
      <div className={styles.filler}></div>

      {/* Thumbnail */}
      <img className={styles.thumbnail} src={imageUrl} />

      {/* Text */}
      <div className={styles.text}>

        {/* Name & collection */}
        <div className={styles.name}>{name}</div>
        <div className={styles.collection}>{collection}</div>

        {/* Keybinding */}
        <div className={styles.keybinding}>
          <div className={styles.key}>
            <div className={styles.character}>O</div>
          </div>
          <div className={styles.legend}>View on OpenSea</div>
        </div>
      </div>
    </div>
  )
}

AssetInfo.propTypes = {
  collection: PropTypes.string,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  onTap: PropTypes.func
}

export default AssetInfo
