import axios from 'axios'
import { fetchAssets } from './nft'
import { getImageRatio } from './util'

export type Art = {
  name: string,
  collection: string,
  description: string,
  id: number,
  imageUrl: string,
  permalink: string,
  ratio: number,
  src: string,
  size: number
}

export const generateArtData = async (address: string): Promise<Art[]> => {
  // Fetch assets for address
  const assets = await fetchAssets(address)

  // Fetch and format NFT data
  const artData = await Promise.all(assets
    .filter(asset => !!asset.imageUrl) // Ensure asset has image url
    .filter(asset => !asset.imageUrl.match(/.svg$/i)) // Filter out .svg images
    .map(async ({ id, name, imageUrl, description, collection, permalink }) => {
      // Declare computed values
      let src, ratio, size

      try {
        // Get image blob
        const { data } = await axios.get(imageUrl, { responseType: 'blob' })

        // Create object URL
        src = URL.createObjectURL(data)

        // Get image ratio
        ratio = await getImageRatio(src)

        // Get image size
        size = data.size
      } catch (error) {
        console.error(`Ignoring asset '${name}'. Could not fetch image at ${imageUrl}.`)
      }

      // Push piece to list of art
      return {
        src,
        ratio,
        name,
        id,
        description,
        collection,
        imageUrl,
        permalink,
        size
      }
    })
  )

  return artData
    // Filter out art without image
    .filter(art => !!art.src)
}
