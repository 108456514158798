import React, { useEffect, useState } from 'react' // eslint-disable-line no-use-before-define
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import * as THREE from 'three'
import { MathUtils } from 'three'
import { useInterval } from 'usehooks-ts'
import { isEqual, find, sumBy, round } from 'lodash'

import { Art, generateArtData } from '../lib/art'
import { resolveAddress } from '../lib/ens'
import { launch } from '../lib/launch'
import Dao from '../lib/mattias/js/Dao/Dao'
import globals from '../lib/globals'
import { dispatch } from '../lib/events'
import { isBrowser, sortByProperty } from '../lib/util'
import VirtualCanvas from '../components/VirtualCanvas/VirtualCanvas'
import { Asset } from '../lib/nft'

// Types
type LoadingError = {
  message: string,
  buttonText: string,
  redirectTarget: string
}

// Constants
const ASSET_INFO_UPDATE_INTERVAL = 250
const ASSET_INFO_MAX_DISTANCE = 25
const ASSET_INFO_MAX_ANGLE = 45

// Globals
let position = [0, 0, 0]
let direction = [0, 0, 0]
let artData: Art[] = []
const defaultAsset = { name: 'n/a', collection: 'n/a', imageUrl: '/images/nft_placeholder.svg' } as Asset

// Helper functions
const resizeCanvases = () => {
  ['#canvas', '#virtual-canvas'].forEach(id => {
    const element = document.querySelector(id) as HTMLElement
    element.style.width = `${window.innerWidth}px`
    element.style.height = `${window.innerHeight}px`
  })
}

// Page
const Gallery = ({ address }) => {
  if (!isBrowser()) return (<></>)

  const [asset, setAsset] = useState<Asset>(defaultAsset)
  const [showAssetInfo, setShowAssetInfo] = useState(false)
  const [showKeybindings, setShowKeybindings] = useState(false)
  const [experienceActive, setExperienceActive] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingError, setLoadingError] = useState<LoadingError>()

  // Launch
  useEffect(() => {
    const run = async () => {
      // Resolve ENS name or Ethereum address
      let resolvedAddress: string
      try {
        resolvedAddress = await resolveAddress(address)
      } catch (err) {
        dispatch('error_invalid_address', { address })
        return setLoadingError({
          message: `Invalid address: ${address}`,
          buttonText: 'Go back',
          redirectTarget: location.origin
        })
      }

      // Generate array of art data
      try {
        artData = await generateArtData(resolvedAddress)
      } catch (err) {
        dispatch('error_opensea_api', { address, error: err.message })
        return setLoadingError({
          message: 'Uh oh, we didn\'t expect SO many gallery guests. Back soon!',
          buttonText: 'Try again',
          redirectTarget: location.href
        })
      }

      // Launch 3D experience
      launch(artData)

      // Handle canvas resizing
      resizeCanvases()
      window.onresize = () => resizeCanvases()

      // Prevent scroll
      document.body.style.overflow = 'hidden'

      // On engine ready
      document.getElementById('canvas').addEventListener('ready', () => {
        // TODO: Replace timeout with event listener, listening for procedural script to complete
        setTimeout(() => setLoading(false), 250)
        const totalSizeMB = round(sumBy(artData, 'size') / 1_000_000, 2)
        dispatch('gallery_launched', { images: artData.length, total_image_size: totalSizeMB })
      })
    }
    run()
  }, [])

  // Tick
  useInterval(async () => {
    // Exit if
    // 1) experience isn't active
    if (!experienceActive) return
    // 2) game api isn't available
    if (!Dao?.gameApi) return
    // 3) no art data exists yet
    if (artData.length === 0) return

    // Get camera position and direction from Dao
    const newPosition = await Dao.gameApi.getCameraPosition()
    const newDirection = await Dao.gameApi.getCameraDirection()

    // If camera hasn't moved -> exit early
    const cameraSteady = isEqual(position, newPosition) && isEqual(direction, newDirection)
    if (cameraSteady) return

    // Update camera position and direction
    position = newPosition
    direction = newDirection

    // Convert camera position/direction to 2D vector
    const cameraDirection = new THREE.Vector2(direction[0], direction[2])
    const cameraPosition = new THREE.Vector2(position[0], position[2])

    // Get camera angle (relative to x axis)
    const cameraAngle = MathUtils.radToDeg(cameraDirection.angle())

    // Get image URL, distance and angle relative to camera for each asset,
    // filtered by max angle and distance
    const filteredAssets = globals.assets
      .map((item) => {
        // Get distance
        const itemPosition = new THREE.Vector2(item.position.x, item.position.z)
        const distance = cameraPosition.distanceTo(itemPosition)

        // Get angle
        const itemDirection = new THREE.Vector2()
          .subVectors(itemPosition, cameraPosition)
          .normalize()
        const itemAngle = MathUtils.radToDeg(itemDirection.angle())
        const angle = Math.abs(cameraAngle - itemAngle)

        // Return mapped asset
        return { imageUrl: item.imgSr, distance, angle }
      })
      // Filter out too high angles or distances
      .filter(asset =>
        asset.angle < ASSET_INFO_MAX_ANGLE &&
        asset.distance < ASSET_INFO_MAX_DISTANCE)
      // Sort assets by angle (smallest first)
      .sort((a, b) => sortByProperty(a, b, 'angle'))

    // If no assets available to show -> hide asset info
    if (filteredAssets.length === 0) {
      setShowAssetInfo(false)
    // Else find best asset to show
    } else {
      // Find asset in art data array
      const asset = find(artData, { src: filteredAssets[0].imageUrl })

      // Set asset
      setAsset(asset)

      // Enable asset info panel
      setShowAssetInfo(true)
    }
  }, ASSET_INFO_UPDATE_INTERVAL)

  const virtualCanvasProps = {
    address,
    asset,
    experienceActive,
    loading,
    showAssetInfo,
    showKeybindings,
    loadingError
  }

  return (
    <>
      {/* Page title and meta tags */}
      <Helmet>
        <title>NFT Gallery - {address}</title>
      </Helmet>

      <div>

      {/* Canvas */}
        <canvas id="canvas"
          // Handle click
          onClick={() => {
            setExperienceActive(true)
            setShowKeybindings(true)
            document.getElementById('canvas').requestPointerLock()
          }}
          // Handle tap
          onTouchEnd={() => setExperienceActive(true)}
        ></canvas>

        {/* Virtual canvas, i.e. 2D layer over engine */}
        <VirtualCanvas {...virtualCanvasProps} />
      </div>

      {/* Mattias' stuff */}
      <div id="HiberGallery" hidden>
        <canvas id="three-canvas"></canvas>
        <div className="ace-wrapper ace1"></div>
        <div className="ace-wrapper ace2"></div>
      </div>
    </>
  )
}

Gallery.propTypes = {
  location: PropTypes.object,
  address: PropTypes.string
}

export default Gallery
