// extracted by mini-css-extract-plugin
export var assetInfo = "AssetInfoDesktop-module--assetInfo--1X85F";
export var filler = "AssetInfoDesktop-module--filler--2oKoh";
export var text = "AssetInfoDesktop-module--text--2i4RN";
export var name = "AssetInfoDesktop-module--name--hzqlI";
export var collection = "AssetInfoDesktop-module--collection--6rfcl";
export var keybinding = "AssetInfoDesktop-module--keybinding--OJkaq";
export var key = "AssetInfoDesktop-module--key--2G6N7";
export var character = "AssetInfoDesktop-module--character--WAd7L";
export var legend = "AssetInfoDesktop-module--legend--1f1Zr";
export var thumbnail = "AssetInfoDesktop-module--thumbnail--sb_RU";