import App from './mattias/js/App'
import { Art } from './art'

export const launch = async (artData: Art[]) => {
  // Get return URL, e.g. 'https://nftgallery.mv'
  const returnUrl = new URL(window.location.href).origin

  // Set game engine launch arguments
  window.WASM_ARGUMENTS = [
    'Launch.InitSessionType=PLAY_SOLO',
    `Platform.FrontendEndpoint=${returnUrl}`,
    'UI.HideAll=true',
    'Platform.IsStandalone=true'
  ]

  // Launch 'app'
  const app = new App(artData)
  app.setup()
}
